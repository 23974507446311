<template>
  <div class="content">
<!--    <navbar :path="path" :show="true"></navbar>-->
    <top :title="title" :titlePic="titlePic"></top>
    <div class="flex">
      <div class="flexItem" v-for="(item,index) in list" :key="index" @click="detail(item)">
        <img :src="item.newsPic" alt="" />
        <div class="title">{{item.title}}</div>
        <div class="detail">{{item.viceTitle}}</div>
        <div class="detail">{{item.createDate}}</div>
        <div class="btn">点击查看</div>
      </div>
    </div>
    <!--分页-->
    <div class="pageBox">
      <div class="page-bar">
        <ul>
          <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
          <li v-if="cur==1"><a class="banclick">上一页</a></li>
          <li v-for="index in indexs" v-bind:class="{ 'actives': cur == index}" :key="index">
            <a v-on:click="btnClick(index)">{{ index }}</a>
          </li>
          <li v-if="cur!=all"><a v-on:click="cur++,pageClick()">下一页</a></li>
          <li v-if="cur == all"><a class="banclick">下一页</a></li>
          <li><a>共<i>{{all}}</i>页</a></li>
        </ul>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
// import navbar from "../components/topBox";
import top from "../components/top";
import bottom from "../components/bottom";
export default {
  name: "dream",
  data(){
    return{
      active:7,
      path:'/Dream',
      title:'中国梦-商贸梦',
      titlePic:[],
      list:[],
      index:1,
      all:0,//总页数
      cur:1,//当前页码
      totalPage:0,//当前条数
    }
  },
  created() {
    this.ListAll()
    this.newList()
  },
  computed:{
    //分页
    indexs: function(){
      var left = 1;
      var right = this.all;
      var ar = [];
      if(this.all>= 5){
        if(this.cur > 3 && this.cur < this.all-2){
          left = this.cur - 2
          right = this.cur + 2
        }else{
          if(this.cur<=3){
            left = 1
            right = 5
          }else{
            right = this.all
            left = this.all -4
          }
        }
      }
      while (left <= right){
        ar.push(left)
        left ++
      }
      return ar
    }
  },
  methods:{
    ListAll(){
      this.$api.get('/home/'+7).then(res=>{
        this.titlePic=res.rows[0].titlePic.split(',')
        let length=res.rows[0].newsList.length
        this.all=Math.ceil(length/12)
      })
    },
    newList(){
      this.$api.get('/home/'+7+'?pageNum='+this.cur+'&pageSize='+12).then(res=>{
        let list=res.rows[0].newsList
        for(let i in list){
          list[i].createDate=list[i].createDate.substring(0,10)
        }
        this.list=list
      })
    },
    detail(det){
      let detail=JSON.stringify(det)
      this.$router.push({name: 'detail',params:{detail:detail}})
    },
    btnClick(data){//页码点击事件
      if(data!=this.cur){
        this.cur=data
        this.newList()
      }
    },
    pageClick(){
      this.newList()
    },
  },
  components: {
    // navbar,
    top,
    bottom
  },
}
</script>

<style scoped>
.flex{
  width: 92.25rem;
  margin-top: 1.6875rem;
  margin-left: 25.75rem;
  z-index:9999;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flexItem{
  width: 21.25rem;
  background: #FFFFFF;
  border-radius: 0.625rem;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-right: 1.75rem;
  margin-bottom: 3.375rem;
}
.flexItem:nth-child(4n){
  margin-right: 0;
}
.flexItem img{
  width: 100%;
  border-radius: 0.625rem;
  height: 16.43rem;
}
.title{
  margin-left: 1.062rem;
  margin-top: 0.812rem;
  font-size: 1.5rem;
  width: 90%;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.detail{
  width: 19.10rem;
  margin-top: 0.625rem;
  margin-left: 1.062rem;
  font-size: 1rem;
  color: #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.btn{
  width: 19.687rem;
  height: 3.062rem;
  line-height: 3.062rem;
  text-align: center;
  font-size: 1.25rem;
  color: #FFFFFF;
  background: #FFB620;
  border-radius: 0.8125rem;
  margin: 0.8125rem auto 0;
}
.flexItem:hover{
  cursor: pointer;
}
/*分页*/
.pageBox{
  width: 110.1875rem;
  margin: 3.5rem auto 4.375rem;
  display: flex;
  justify-content: flex-end;
}
ul,li{
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}
li{
  list-style: none
}
.page-bar li:first-child>a {
  margin-left: 0px
}
.page-bar a{
  background: #FFFFFF;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 0.375rem 0.75rem;
  margin-left: -0.0625px;
  line-height: 1.42857143;
  color: #5D6062;
  cursor: pointer;
  margin-right: 0.4375rem;
  border-radius: 0.3125rem;
}
/*.page-bar a:hover{*/
/*  background-color: #F5F8FF;*/
/*}*/
.page-bar a.banclick{
  cursor:not-allowed;
}
.page-bar .actives a{
  color: #fff;
  cursor: default;
  background: #FFB620;
  /*border-color: #E96463;*/
}
.page-bar i{
  font-style:normal;
  color: #d44950;
  margin: 0px 0.25rem;
  font-size: 0.75rem;
}
</style>