<template>
  <div class="content">
    <div class="swiper-container">
      <swiper class="swiper swiper-wrapper" :options="banner" v-if="bannerArr.length > 0">
        <swiper-slide class="slide-1" v-for="(item,index) in bannerArr" :key="index"><img :src="item" alt=""/>
        </swiper-slide>
      </swiper>
    </div>
    <div class="navBox">
      <div class="navTitle">{{title}}</div>
      <div class="navdetail">{{detail}}</div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiperPhone",
  data(){
    return{
      banner: {
        autoplay: true,
        loop: true,
        loopedSlides: 10, // looped slides should be the same
        spaceBetween: 0,
      },
    }
  },
  props: {
    title:String,
    detail:String,
    bannerArr:Array
  },
}
</script>

<style scoped>
.swiper-container{
  position: fixed;
  top: 0.92rem;left: 0;
  width: 100%;
  height: 3.35rem;
}
.swiper-wrapper>.slide-1{
  width: 100vw;
  height: 100%;
  text-align: center;
  position: relative;
}
.slide-1>img {
  margin: auto;
  width: 100%;
  height: 100%;
}
.navBox{
  width: 7.23rem;
  height: 1.54rem;
  border-radius: 0.2rem;
  margin: 4.48rem auto 0;
  background: #FFFFFF;
  padding: 0.42rem 0 0.4rem;
}
.navTitle{
  text-align: center;
  font-size: 0.56rem;
  font-weight: bold;
}
.navdetail{
  font-size: 0.36rem;
  color: #202F44;
  text-align: center;
}
.line{
  width: 3.75rem;
  height: 0.12rem;
  background: #FFB620;
  margin: 0.24rem auto 0;
}
</style>