<template>
  <div class="content">
    <navbar></navbar>
    <swiper :bannerArr="bannerArr" :title="title" :detail="detail"></swiper>
    <div class="con">
      <div v-for="(item,index) in list" :key="index">
        <img :src="item.newsPic" alt="" @click="de(item.content)"/>
      </div>
    </div>
    <div class="kong"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import navbar from "@/components/tophone";
import bottom from "@/components/bottomPhone";
import swiper from "@/components/swiperPhone";
export default {
  name: "floor",
  data(){
    return{
      bannerArr:[],
      title:'',
      detail:'',
      list:[],
      ritchtext:''
    }
  },
  created() {
    this.bannerArr=JSON.parse(this.$route.query.arr)
    this.title=this.$route.query.title
    this.detail=this.$route.query.detail
    let that=this
    that.$api.get('/home/'+4).then(res=>{
      that.ritchtext=res.rows[0].moduleDetail
      that.list=res.rows[0].newsList
    })
  },
  methods:{
    de(det){
      let detail=JSON.stringify(det)
      this.$router.push({name:'phoneDetail',params:{detail:detail}})
    },
  },
  components: {
    navbar,
    bottom,
    swiper
  },
}
</script>

<style scoped>
.con{
  width: 100%;
  padding: 0.12rem 0;
  background: #FFFFFF;
  margin-top: 0.2rem;
}
.con img{
  width: 7.23rem;
  height: 3.79rem;
  margin: 0 auto 0.24rem;
}
</style>