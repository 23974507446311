<template>
  <div class="content">
    <div class="top">
      <div class="img">
        <swiper class="swiper swiper-wrapper" :options="banner" v-if="titlePic.length > 0">
          <swiper-slide class="slide-1" v-for="(item,index) in titlePic" :key="index"><img :src="item" alt=""/>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" id="pagination"></div>
        </swiper>
      </div>
      <div class="top-1-1">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
name: "top",
  data() {
    return {
      nav:0,
      bannerArr: [],
      banner: {
        autoplay: true,
        loop: true,
        loopedSlides: 10, // looped slides should be the same
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass: 'my-bullet-active',
          bulletClass : 'my-bullets',
        },
      },
    }
  },
  props: {
    title:String,
    titlePic:Array
  },
  watch:{
    titlePic:function (val){
      if(val.length==1){
        this.banner.autoplay=false
      }
    }
  }
}
</script>

<style scoped>
.top{
  margin-top: 1.25rem;
  margin-left: 25.75rem;
}
.img{
  width: 90.437rem;
  height: 25.187rem;
  border-radius: 1rem;
}
.swiper-wrapper>.slide-1{
  width: 100vw;
  height: 100%;
  text-align: center;line-height: 67.625rem;
  position: relative;
}
.slide-1>img {
  margin: auto;
  width: 100%;
  height: 100%;
}
.top-1-1{
  font-size: 2.25rem;
  margin-top: 1rem;
  border-bottom: 0.2rem solid#FFB620;
  width: 90.437rem;
  padding-bottom: 0.25rem;
  background: #F0F2F5;
}
.swiper-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  bottom:1.375rem!important ;
}
</style>