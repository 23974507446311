<template>
  <div class="content">
    <div class="kong"></div>
    <div class="bottom"><p @click="href('https://beian.miit.gov.cn')">  陕ICP备18012890号-3</p>
      <img src="../assets/img/bottom.png" alt="" /> <p @click="href('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010202000111')">陕公网安备 61010202000111号</p></div>
  </div>
</template>

<script>
export default {
name: "bottomPhone",
  methods:{
    href(url){
      window.location.href=url
    },
  }
}
</script>

<style scoped>
.bottom{
  width: 100%;
  font-size: 0.24rem;
  height: 0.66rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;left: 0;
  color: #8C8C8C;
  background: #FFFFFF;
  text-align: center;
}
.bottom p{
  margin-left: 0.12rem;
}
.bottom img{
  margin-left: 0.12rem;
  width: 0.3rem;
  height: 0.3rem;
}
</style>