<template>
  <div class="content">
    <navbar></navbar>
    <swiper :bannerArr="bannerArr" :title="title" :detail="detail"></swiper>
    <div class="detail">
      <p v-html="ritchtext"></p>
    </div>
    <div class="kong"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import navbar from "@/components/tophone";
import bottom from "@/components/bottomPhone";
import swiper from "@/components/swiperPhone";
export default {
  name: "enter",
  data(){
    return{
      bannerArr:[],
      title:'',
      ritchtext:''
    }
  },
  created() {
    this.bannerArr=JSON.parse(this.$route.query.arr)
    this.title=this.$route.query.title
    this.detail=this.$route.query.detail
    let that=this
    that.$api.get('/home/'+9).then(res=>{
      let ritchtext=res.rows[0].moduleDetail
      that.ritchtext=that.showHtml(ritchtext)
    })
  },
  methods:{
    showHtml(str){
      return str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&lt;/g,"<")
          .replace(/&gt;/g,">")
          .replace(/&quot;/g,"\"")
          .replace(/&#39;/g, "\"")
          .replace(/&amp;nbsp;/g,'\u3000')
    },
  },
  components: {
    navbar,
    bottom,
    swiper
  },
}
</script>

<style scoped>
.kong{
  width: 100%;
  height: 1rem;
}
.detail{
  width: 90%;
  padding: 0.24rem;
  background: #FFFFFF;
  font-size: 0.24rem;
  margin: 0.2rem auto 0;
}
.detail>>>img{
  width: 100%;
  display: block;
  object-fit: fill;
}
.detail>>>p{
  width: 100%;
  display: block;
  white-space: pre-wrap;
}
</style>