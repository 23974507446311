<template>
  <div class="content">
    <navbar></navbar>
    <div class="swiper-container">
      <swiper class="swiper swiper-wrapper" :options="banner" v-if="bannerArr.length > 0">
        <swiper-slide class="slide-1" v-for="(item,index) in bannerArr" :key="index"><img :src="item" alt=""/>
        </swiper-slide>
      </swiper>
    </div>
    <div class="navBox">
      <div class="navTitle">导航分类</div>
      <div class="navdetail">Navigation classification</div>
      <div class="line"></div>
    </div>
    <img :src="imgArr[0]" alt="" class="img" @click="detail(imgArr,0)" v-if="imgArr"/>
    <img :src="imgArr1[0]" alt="" class="img" @click="detail(imgArr1,1)" v-if="imgArr1"/>
    <img :src="imgArr2[0]" alt="" class="img" @click="detail(imgArr2,2)" v-if="imgArr2"/>
    <img :src="imgArr3[0]" alt="" class="img" @click="detail(imgArr3,3)" v-if="imgArr3"/>
    <img :src="imgArr4[0]" alt="" class="img" @click="detail(imgArr4,4)" v-if="imgArr4"/>
    <img :src="imgArr5[0]" alt="" class="img" @click="detail(imgArr5,5)" v-if="imgArr5"/>
    <img :src="imgArr6[0]" alt="" class="img" @click="detail(imgArr6,6)" v-if="imgArr6"/>
    <img :src="imgArr7[0]" alt="" class="img" @click="detail(imgArr7,7)" v-if="imgArr7"/>
    <img :src="imgArr8[0]" alt="" class="img" @click="detail(imgArr8,8)" v-if="imgArr8"/>
    <div class="kong"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import "@/until/base";
import navbar from "@/components/tophone";
import bottom from "@/components/bottomPhone";
import {swiper, swiperSlide} from 'vue-awesome-swiper'
export default {
  name: "index",
  data(){
    return{
      bannerArr: [],
      banner: {
        autoplay: true,
        loop: true,
        loopedSlides: 10, // looped slides should be the same
        spaceBetween: 0,
      },
      imgArr:[],
      imgArr1:[],
      imgArr2:[],
      imgArr3:[],
      imgArr4:[],
      imgArr5:[],
      imgArr6:[],
      imgArr7:[],
      imgArr8:[],
    }
  },
  created() {
    let that=this
    that.$api.get('/home/'+1).then(res=>{
      that.bannerArr=res.rows[0].phoneTitlePic.split(',')
      if(that.bannerArr.length==1){
        this.banner.autoplay=false
      }
    })
    that.$api.get('/home/'+2).then(res=>{
      that.imgArr=res.rows[0].titlePic.split(',')
      that.ritchtext=res.rows[0].moduleDetail
    })
    that.$api.get('/home/'+3).then(res=>{
      that.imgArr1=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+4).then(res=>{
      that.imgArr2=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+5).then(res=>{
      that.imgArr3=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+6).then(res=>{
      that.imgArr4=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+7).then(res=>{
      that.imgArr5=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+8).then(res=>{
      that.imgArr6=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+9).then(res=>{
      that.imgArr7=res.rows[0].titlePic.split(',')
    })
    that.$api.get('/home/'+10).then(res=>{
      that.imgArr8=res.rows[0].titlePic.split(',')
    })
  },
  methods:{
    detail(arr,num){
      let data=JSON.stringify(arr)
      if(num==0){
        this.$router.push({name:'phoneEnter',query:{arr:data,title:'公司简介',detail:'Company Profile'}})
      }else if(num==1){
        this.$router.push({name:'phoneDynamic',query:{arr:data,title:'企业动态',detail:'Enterprise Dynamics'}})
      }else if(num==2){
        this.$router.push({name:'phoneFloor',query:{arr:data,title:'楼层业态',detail:'Floor format'}})
      }else if(num==3){
        this.$router.push({name:'phoneBranchIntroduction',query:{arr:data,title:'支部简介',detail:'Branch Introduction'}})
      }else if(num==4){
        this.$router.push({name:'phoneBranchActivities',query:{arr:data,title:'支部活动',detail:'Branch activities'}})
      }else if(num==5){
        this.$router.push({name:'phoneDream',query:{arr:data,title:'中国梦-商贸梦',detail:'China Dream - Business Dream'}})
      }else if(num==6){
        this.$router.push({name:'phoneMerchant',query:{arr:data,title:'商户之家',detail:'Home of Merchants'}})
      }else if(num==7){
        this.$router.push({name:'phoneJoin',query:{arr:data,title:'加入我们',detail:'Join us'}})
      }else{
        this.$router.push({name:'phoneLove',query:{arr:data,title:'爱心公益',detail:'MERCHANT HOME'}})
      }
    }
  },
  components: {
    navbar,
    bottom,
    swiper,
    swiperSlide,
  },
}
</script>

<style scoped>
.kong{
  width: 100%;
  height: 1rem;
}
.swiper-container{
  position: fixed;
  top: 0.92rem;left: 0;
  width: 100%;
  height: 4.32rem;
}
.swiper-wrapper>.slide-1{
  width: 100vw;
  height: 100%;
  text-align: center;
  position: relative;
}
.slide-1>img {
  margin: auto;
  width: 100%;
  height: 100%;
}
.navBox{
  width: 7.23rem;
  height: 1.54rem;
  border-radius: 0.2rem;
  margin: 5.38rem auto 0;
  background: #FFFFFF;
  padding: 0.42rem 0 0.4rem;
}
.navTitle{
  text-align: center;
  font-size: 0.56rem;
  font-weight: bold;
}
.navdetail{
  font-size: 0.36rem;
  color: #202F44;
  text-align: center;
}
.line{
  width: 3.75rem;
  height: 0.12rem;
  background: #FFB620;
  margin: 0.24rem auto 0;
}
.img{
  width: 7.23rem;
  height: 3.24rem;
  margin: 0.22rem auto 0;
}
</style>