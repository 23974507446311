<template>
  <div class="content">
    <navbar></navbar>
    <swiper :bannerArr="bannerArr" :title="title" :detail="detail"></swiper>
    <list :num="num"></list>
    <div class="kong"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import navbar from "@/components/tophone";
import bottom from "@/components/bottomPhone";
import swiper from "@/components/swiperPhone";
import list from "@/components/list";
export default {
  name: "dynamic",
  data(){
    return{
      bannerArr:[],
      title:'',
      index:1,
      all:0,//总页数
      cur:1,//当前页码
      totalPage:0,//当前条数
      num:3
    }
  },
  created() {
    this.bannerArr=JSON.parse(this.$route.query.arr)
    this.title=this.$route.query.title
    this.detail=this.$route.query.detail
  },
  components: {
    navbar,
    bottom,
    swiper,
    list,
  },
}
</script>

<style scoped>

</style>