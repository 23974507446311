<template>
  <div class="content">
    <div class="top">
      <img src="../assets/icon.png" alt="" />
      <div class="btn" @click="pop">联系我们</div>
    </div>
    <div class="popBox" v-if="isShow" @click="pop"></div>
    <div class="popCon" v-if="isShow">
      <div class="popTitle">{{name}}</div>
      <img :src="img" alt="" />
      <div class="flex">
        <div class="item" :class="{'active1':type==0}" @click="qh(0)">公众号</div>
        <div class="item" :class="{'active1':type==1}" @click="qh(1)">抖音号</div>
        <div class="item" :class="{'active1':type==2}" @click="qh(2)">视频号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tophone",
  data(){
    return{
      name:'公众号',
      xinxiArr:{},
      type:0,
      list:{},
      img:'',
      isShow:false,
    }
  },
  created() {
    let data=sessionStorage.getItem('data')
    if(data){
      this.list=JSON.parse(data)
      this.img=JSON.parse(data).publicCode
      this.companyName=JSON.parse(data).companyName
    }else{
      this.$api.get('/home/company/info').then(res => {
        let data=res.data
        this.list=data
        this.img=data.publicCode
        this.companyName=data.companyName
        sessionStorage.setItem('data',JSON.stringify(data))
      })
    }
  },
  methods:{
    pop(){
      this.isShow=!this.isShow
    },
    qh(num){
      if(num==this.type){
        return ;
      }
      this.type=num
      if(num==0){
        this.img=this.list.publicCode
        this.name='公众号'
      }else if(num==1){
        this.img=this.list.privateCode
        this.name='抖音号'
      }else{
        this.name='视频号'
        this.img=this.list.companyLogo
      }
    },
  }
}
</script>

<style scoped>
.top{
  width: 100%;
  height: 0.92rem;
  background: #FFFFFF;
  position: fixed;
  top: 0;left: 0;
  display: flex;align-items: center;justify-content: space-between;
  z-index:99;
}
.top img{
  width: 1.24rem;
  height: 0.54rem;
  margin-left: 0.28rem;
}
.btn{
  width: 1.3rem;
  height: 0.4rem;
  background: #FFB620;
  border-radius: 0.4rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.24rem;
  margin-right: 0.2rem;
}
.popBox{
  position: fixed;
  top: 0;left: 0;width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.6);
  z-index:9;
}
.popCon{
  width: 6.82rem;
  padding: 0.36rem 0;
  height: 8rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  position: fixed;top: 0;left: 0;right: 0;bottom: 0;
  z-index: 99;
  margin: auto;
}
.popTitle{
  font-size: 0.48rem;
  font-weight: bold;
  text-align: center;
}
.popCon img{
  width: 5.42rem;
  height: 5.42rem;
  margin: 0.36rem auto 0.6rem;
}
.flex{
  width: 5.42rem;
  display: flex;justify-content: space-between;
  margin: 0 auto;
}
.item{
  width: 1.2rem;
  height: 0.56rem;
  background: #F7F8FC;color: #979DAD;
  text-align: center;line-height: 0.56rem;
  border-radius: 0.6rem;font-size: 0.24rem;
}
.active1{
  background: #FFB620;
  color: #FFFFFF;
}
.bottom{
  width: 100%;
  font-size: 0.24rem;
  height: 0.66rem;
  position: fixed;
  bottom: 0;left: 0;
  margin-top: 0.16rem;
  color: #8C8C8C;
  background: #FFFFFF;
}
</style>