<template>
  <div class="content">
    <div class="swiper-container">
      <swiper class="swiper swiper-wrapper" :options="banner" v-if="bannerArr.length > 0">
        <swiper-slide class="slide-1" v-for="(item,index) in bannerArr" :key="index"><img :src="item" alt=""/>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" id="pagination"></div>
      </swiper>
    </div>
<!--    <navbar :path="'/'"></navbar>-->
    <bottom></bottom>
  </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper'
// import navbar from "../components/topBox";
import bottom from "../components/bottom";
export default {
name: "index",
  data() {
    return {
      active:0,
      bannerArr: [],
      banner: {
        autoplay: true,
        loop: true,
        loopedSlides: 10, // looped slides should be the same
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass: 'my-bullet-active',
          bulletClass : 'my-bullet',
        },
      },
    }
},
  created() {
    let that=this
    that.$api.get('/home/'+1).then(res=>{
      that.bannerArr=res.rows[0].titlePic.split(',')
      if(that.bannerArr.length==1){
        this.banner.autoplay=false
      }
    })
  },
  components: {
    swiper,
    swiperSlide,
    // navbar,
    bottom
  },
}
</script>

<style scoped>
.swiper-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.swiper-wrapper>.slide-1{
  width: 100vw;
  height: 100%;
  text-align: center;line-height: 67.625rem;
  position: relative;
}
.slide-1>img {
  margin: auto;
  width: 100%;
  height: 100%;
}
.swiper-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  bottom:1.375rem!important ;
}
</style>