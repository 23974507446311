<template>
  <div class="content">
    <navbar></navbar>
    <div class="detail">
      <div class="cc">{{con}}</div>
      <div class="time">{{time}}</div>
      <p v-html="ritchtext"></p>
    </div>
    <div class="kong"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import navbar from "@/components/tophone";
import bottom from "@/components/bottomPhone";
export default {
name: "detail",
  data(){
    return{
      ritchtext:'',
      con:'',
      time:''
    }
  },
  activated() {
    let data=JSON.parse(this.$route.params.detail)
    let ritchtext=data.content
    this.ritchtext=this.showHtml(ritchtext)
    this.con=data.title
    this.time=data.createDate
  },
  methods:{
    showHtml(str){
      return str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&lt;/g,"<")
          .replace(/&gt;/g,">")
          .replace(/&quot;/g,"\"")
          .replace(/&#39;/g, "\"")
          .replace(/&amp;nbsp;/g,'\u3000')
    },
  },
  components: {
    navbar,
    bottom,
  },
}
</script>

<style scoped>
.kong{
  width: 100%;
  height: 1rem;
}
.detail{
  width: 90%;
  padding: 0.24rem;
  background: #FFFFFF;
  font-size: 0.24rem;
  margin: 1.4rem auto 0;
}
.cc{
  font-size: 0.26rem;
  font-weight: bold;
}
.time{
  font-size: 0.12rem;
  color: #959595;
  margin: 0.1rem auto;
}
.detail>>>img{
  width: 100%;
  display: block;
  object-fit: fill;
}
.detail>>>p{
  width: 100%;
  display: block;
  white-space: pre-wrap;
}
</style>