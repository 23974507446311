<template>
  <div class="content">
    <div class="detail">
      <div class="cc">{{ con }}</div>
      <div class="time">{{ time }}</div>
      <p v-html="ritchtext"></p>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "../components/bottom";
export default {
  name: "detail",
  data() {
    return {
      title: "楼层业态",
      ritchtext: "",
      con: "",
      time: "",
    };
  },
  components: {
    bottom,
  },
  activated() {
    console.log();
    let data = [];
    if (this.$route.params.detail) {
      data = JSON.parse(this.$route.params.detail);
    }

    if (this.$route.params.detail) {
      console.log(999999);
      sessionStorage.setItem("datacontent", JSON.stringify(data.content));
      sessionStorage.setItem("datatitle", JSON.stringify(data.title));
      sessionStorage.setItem("createdate", JSON.stringify(data.createDate));
    }
    let s11 = sessionStorage.getItem("datacontent");
    let s22 = sessionStorage.getItem("datatitle");
    let s33 = sessionStorage.getItem("createdate");
    console.log(s11);
    console.log(s22);
    console.log(s33);

    this.path = this.$route.params.path;

    // let ritchtext = data.content;
    // console.log(ritchtext);
    // this.ritchtext = this.showHtml(ritchtext);
    // this.con = data.title;
    // console.log(this.con);
    // this.time = data.createDate;

    let ritchtext = JSON.parse(s11);
    console.log(ritchtext);
    this.ritchtext = this.showHtml(ritchtext);
    this.con = JSON.parse(s22);
    console.log(this.con);
    this.time = JSON.parse(s33);
  },
  methods: {
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, '"')
        .replace(/&amp;nbsp;/g, "\u3000");
    },
  },
};
</script>

<style scoped>
.detail {
  width: 90.25rem;
  margin: 2rem 0 2rem 27rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.625rem;
  font-size: 1rem;
  line-height: 2rem;
}
.cc {
  font-size: 2rem;
  font-weight: bold;
}
.time {
  font-size: 1rem;
  color: #959595;
  margin-top: 0.4rem;
}
.detail >>> img {
  width: 100%;
  display: unset;
  object-fit: fill;
}
.detail >>> p {
  width: 100%;
  display: block;
  white-space: pre-wrap;
}
</style>