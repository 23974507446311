<template>
  <div class="content">
<!--    <navbar :path="'/Merchant'"></navbar>-->
    <top :title="title" :titlePic="titlePic"></top>
    <div class="con">
      <div class="conFlex">
        消防法规
        <div class="line"></div>
      </div>
      <img :src="obj.newsPic" alt="" class="img" @click="detail(obj)"/>
      <div class="conFlex" style="margin-bottom: 0.625rem">
        管理规定
        <div class="line"></div>
      </div>
      <div  style="display: flex;justify-content: flex-start;gap:20px;flex-wrap: wrap; margin-top: 2.312rem">
		<div v-if="index>0" v-for="(item,index) in list" :key="index">
			<div >
			  <img :src="item.newsPic" alt="" class="imgs" @click="detail(item.content)"  />
			</div>
		</div>
	


      </div>
    </div>
    <!--分页-->
    <div class="pageBox">
      <div class="page-bar">
        <ul>
          <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
          <li v-if="cur==1"><a class="banclick">上一页</a></li>
          <li v-for="index in indexs" v-bind:class="{ 'actives': cur == index}" :key="index">
            <a v-on:click="btnClick(index)">{{ index }}</a>
          </li>
          <li v-if="cur!=all"><a v-on:click="cur++,pageClick()">下一页</a></li>
          <li v-if="cur == all"><a class="banclick">下一页</a></li>
          <li><a>共<i>{{all}}</i>页</a></li>
        </ul>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
// import navbar from "@/components/topBox";
import top from "@/components/top";
import bottom from "../components/bottom";
export default {
  name: "merchant",
  data(){
    return{
      active:8,
      title:'商户之家',
      list:[],
      titlePic:[],
      index:1,
      all:0,//总页数
      cur:1,//当前页码
      totalPage:0,//当前条数
      obj:{}
    }
  },
  created() {
    this.ListAll()
    this.newList()
  },
  computed:{
    //分页
    indexs: function(){
      var left = 1;
      var right = this.all;
      var ar = [];
      if(this.all>= 5){
        if(this.cur > 3 && this.cur < this.all-2){
          left = this.cur - 2
          right = this.cur + 2
        }else{
          if(this.cur<=3){
            left = 1
            right = 5
          }else{
            right = this.all
            left = this.all -4
          }
        }
      }
      while (left <= right){
        ar.push(left)
        left ++
      }
      return ar
    }
  },
  methods:{
    ListAll(){
      this.$api.get('/home/'+8).then(res=>{
        this.titlePic=res.rows[0].titlePic.split(',')
        let length=res.rows[0].newsList.length
        this.obj=res.rows[0].newsList[0]
        this.all=Math.ceil(length/12)
      })
    },
    newList(){
      this.$api.get('/home/'+8+'?pageNum='+this.cur+'&pageSize='+12).then(res=>{
        this.list=res.rows[0].newsList
      })
    },
    detail(det){
      let detail=JSON.stringify(det)
      this.$router.push({name: 'detail',params:{detail:detail}})
    },
    btnClick(data){//页码点击事件
      if(data!=this.cur){
        this.cur=data
        this.newList()
      }
    },
    pageClick(){
      this.newList()
    },
  },
  components: {
    // navbar,
    top,
    bottom
  },
}
</script>

<style scoped>
.con{
  width: 87.437rem;
  padding: 1.5rem;
  margin-bottom: 1.81rem;
  background: #FFFFFF;
  border-radius: 0.625rem;
  margin-top: 1.68rem;
  margin-left: 25.75rem;
  z-index:9999;
}
.conFlex{
  width: 88.812rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 1.875rem;
  font-weight: bold;
}
.line{
  width: 80.5rem;
  height: 0.062rem;
  background: #E4E4E4;
}
.img{
  width: 88.812rem;
  height: 25rem;
  margin: 2.312rem auto 1.687rem;
}
.img:hover{
  cursor:pointer;
}
.imgs{
  width: 40.75rem;
  height: 24.5rem;
}
.imgs:hover{
  cursor:pointer;
}
/*分页*/
.pageBox{
  width: 110.1875rem;
  margin: 3.5rem auto 4.375rem;
  display: flex;
  justify-content: flex-end;
}
ul,li{
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}
li{
  list-style: none
}
.page-bar li:first-child>a {
  margin-left: 0px
}
.page-bar a{
  background: #FFFFFF;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 0.375rem 0.75rem;
  margin-left: -0.0625px;
  line-height: 1.42857143;
  color: #5D6062;
  cursor: pointer;
  margin-right: 0.4375rem;
  border-radius: 0.3125rem;
}
/*.page-bar a:hover{*/
/*  background-color: #F5F8FF;*/
/*}*/
.page-bar a.banclick{
  cursor:not-allowed;
}
.page-bar .actives a{
  color: #fff;
  cursor: default;
  background: #FFB620;
  /*border-color: #E96463;*/
}
.page-bar i{
  font-style:normal;
  color: #d44950;
  margin: 0px 0.25rem;
  font-size: 0.75rem;
}
</style>