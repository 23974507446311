<template>
  <div class="content">
<!--    <navbar :path="'/FloorFormat'"></navbar>-->
    <top :title="title" :titlePic="titlePic"></top>
    <div class="con">
      <div class="conFlex">
        地下一层
        <div class="line"></div>
      </div>
      <img :src="list[0].newsPic" alt="" class="img" @click="detail(list[0])" v-if="list[0]"/>
      <div class="conFlex" style="margin-bottom: 0.625rem">
        地上七层
        <div class="line"></div>
      </div>
      <img :src="list[1].newsPic" alt="" class="imgs" @click="detail(list[1])" v-if="list[1]"/>
      <img :src="list[2].newsPic" alt="" class="imgs" @click="detail(list[2])" v-if="list[2]"/>
      <img :src="list[3].newsPic" alt="" class="imgs" @click="detail(list[3])" v-if="list[3]"/>
      <img :src="list[4].newsPic" alt="" class="imgs" @click="detail(list[4])" v-if="list[4]"/>
      <img :src="list[5].newsPic" alt="" class="imgs" @click="detail(list[5])" v-if="list[5]"/>
      <img :src="list[6].newsPic" alt="" class="imgs" @click="detail(list[6])" v-if="list[6]"/>
      <img :src="list[7].newsPic" alt="" class="imgs" @click="detail(list[7])" v-if="list[7]"/>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
// import navbar from "@/components/topBox";
import top from "@/components/top";
import bottom from "../components/bottom";
export default {
name: "floorFormat",
  data(){
    return{
      title:'楼层业态',
      active:3,
      titlePic:[],
      list:[]
    }
  },
  created() {
    this.ListAll()
  },
  components: {
    top,
    bottom
  },
  methods:{
    ListAll(){
      this.$api.get('/home/'+4).then(res=>{
        this.titlePic=res.rows[0].titlePic.split(',')
        this.list=res.rows[0].newsList
      })
    },
    detail(det){
      let detail=JSON.stringify(det)
      this.$router.push({name:'detail',params:{detail:detail}})
    },
  }
}
</script>

<style scoped>
.con{
  width: 87.437rem;
  padding: 1.5rem;
  margin-bottom: 1.81rem;
  background: #FFFFFF;
  border-radius: 0.625rem;
  margin-top: 1.68rem;
  margin-left: 25.75rem;
  z-index:9999;
}
.conFlex{
  width: 88.812rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 1.875rem;
  font-weight: bold;
}
.line{
  width: 80.5rem;
  height: 0.062rem;
  background: #E4E4E4;
}
.img{
  width: 88.812rem;
  height: 25rem;
  margin: 2.312rem auto 1.687rem;
}
.img:hover{
  cursor:pointer;
}
.imgs{
  width: 88.812rem;
  height: 17.375rem;
  margin: 1.68rem auto 0;
}
.imgs:hover{
  cursor:pointer;
}
</style>