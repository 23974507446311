<template>
  <div class="content">
    <div class="flex">
      <div class="item" v-for="(item,index) in list" :key="index" @click="detail(item)">
        <img :src="item.newsPic" alt="" />
        <div class="title">{{item.title}}</div>
        <div class="detail">{{item.viceTitle}}</div>
        <div class="time">{{item.createDate}}</div>
        <div class="look">立即查看</div>
      </div>
    </div>
    <!--分页-->
    <div class="pageBox">
      <div class="page-bar">
        <ul>
          <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
          <li v-if="cur==1"><a class="banclick">上一页</a></li>
          <li v-for="index in indexs" v-bind:class="{ 'actives': cur == index}" :key="index">
            <a v-on:click="btnClick(index)">{{ index }}</a>
          </li>
          <li v-if="cur!=all"><a v-on:click="cur++,pageClick()">下一页</a></li>
          <li v-if="cur == all"><a class="banclick">下一页</a></li>
          <!--          <li><a>共<i>{{all}}</i>页</a></li>-->
        </ul>
      </div>
    </div>
    <div class="bottom">共<i>{{all}}</i>页</div>
  </div>
</template>

<script>
export default {
name: "list",
  data(){
    return{
      list:[],
      index:1,
      all:0,//总页数
      cur:1,//当前页码
      totalPage:0,//当前条数
    }
  },
  props:{
    num:Number
  },
  created() {
    this.num=this.$parent.num
    this.ListAll()
    this.newList()
  },
  computed:{
    //分页
    indexs: function(){
      var left = 1;
      var right = this.all;
      var ar = [];
      if(this.all>= 5){
        if(this.cur > 3 && this.cur < this.all-2){
          left = this.cur - 2
          right = this.cur + 2
        }else{
          if(this.cur<=3){
            left = 1
            right = 5
          }else{
            right = this.all
            left = this.all -4
          }
        }
      }
      while (left <= right){
        ar.push(left)
        left ++
      }
      return ar
    }
  },
  methods:{
    ListAll(){
      this.$api.get('/home/'+this.num).then(res=>{
        let length=res.rows[0].newsList.length
        this.all=Math.ceil(length/12)
      })
    },
    newList(){
      this.$api.get('/home/'+this.num+'?pageNum='+this.cur+'&pageSize='+12).then(res=>{
        let list=res.rows[0].newsList
        for(let i in list){
          list[i].createDate=list[i].createDate.substring(0,10)
        }
        this.list=list
      })
    },
    detail(det){
      let detail=JSON.stringify(det)
      this.$router.push({name: 'phoneDetail',params:{detail:detail}})
    },
    btnClick(data){//页码点击事件
      if(data!=this.cur){
        this.cur=data
        this.newList()
      }
    },
    pageClick(){
      this.newList()
    },
  },
}
</script>

<style scoped>
.flex{
  width: 7.23rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0.44rem auto 0;
}
.item{
  width: 3.58rem;
  padding-bottom: 0.12rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
  overflow: hidden;
  margin-bottom: 0.22rem;
}
.item img{
  width: 100%;
  height: 2.34rem;
}
.title{
  font-size: 0.28rem;
  width: 95%;
  margin: 0.1rem auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.detail{
  font-size: 0.2rem;
  margin: 0 auto 0;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #BBBBBB;
}
.time{
  font-size: 0.2rem;
  margin: 0.1rem auto 0;
  width: 95%;
  color: #BBBBBB;
}
.look{
  width: 3.24rem;
  height: 0.48rem;
  line-height: 0.48rem;
  margin: 0.18rem auto 0;
  background: #FFB620;
  border-radius: 0.1rem;
  text-align: center;
  font-size: 0.24rem;
  color:#FFFFFF;
}
/*分页*/
.pageBox{
  /*width: 7.06rem;*/
  margin: 0.76rem auto 0.2rem;
  display: flex;
  justify-content: center;
}
ul,li{
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}
li{
  list-style: none
}
.page-bar li:first-child>a {
  margin-left: 0px;
}
.page-bar a{
  background: #FFFFFF;
  text-decoration: none;
  position: relative;
  float: left;
  text-align: center;
  margin-left: -0.0625px;
  line-height: 0.82rem;
  color: #5D6062;
  cursor: pointer;
  margin-right: 0.14rem;
  border-radius: 0.1rem;
  font-size: 0.12rem;
  width: 0.86rem;
  height: 0.86rem;
  display: block;
}
.page-bar a.banclick{
  cursor:not-allowed;
}
.page-bar .actives a{
  color: #fff;
  cursor: default;
  background: #FFB620;
}
.bottom{
  font-size: 0.12rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.bottom i{
  font-style:normal;
  color: #d44950;
  margin: 0 0.25rem;
}
</style>