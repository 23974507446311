<template>
  <div class="content">
    <div class="botttom_2">{{ companyName }} <p @click="href('https://beian.miit.gov.cn')">  陕ICP备18012890号-3</p>
    <img src="../assets/img/bottom.png" alt="" /> <p @click="href('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010202000111')">陕公网安备 61010202000111号</p></div>
  </div>
</template>

<script>
export default {
name: "bottom",
  data() {
    return {
      companyName:'陕西银邦置业有限责任公司 版权所有 @2017 YINBANG GROUP All' + 'Rights Reserved',
      bottom:''
    }
  },
  watch:{
    companyName:function (val){
      this.companyName=val
    }
  },
  created() {
    let data=sessionStorage.getItem('data')
    if(data){
      this.companyName=JSON.parse(data).companyName
    }
  },
  methods:{
    href(url){
      window.location.href=url
    },
  }
}
</script>

<style scoped>
.botttom_2{
  width: 100%;
  height: 2.0625rem;
  line-height: 2.0625rem;
  font-size:1rem;
  color: #8C8C8C;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#F0F2F5;
  position: fixed;
  bottom: 0;left: 0;
  z-index:9999;
}
.botttom_2:hover{
  cursor: pointer;
}
.botttom_2 p{
  margin-left: 0.8rem;
}
.botttom_2 img{
  margin-left: 0.4rem;
  width: 20rpx;
  height: 20rpx;
}
</style>