<template>
  <div class="content">
<!--    <navbar :path="'/Enter'"></navbar>-->
    <top :title="title" :titlePic="titlePic"></top>
    <div class="con">
      <p v-html="ritchtext"></p>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
// import navbar from "../components/topBox";
import top from "../components/top";
import bottom from "../components/bottom";
export default {
  name: "enter",
  data(){
    return{
      active:1,
      title:'公司简介',
      titlePic:[],
      ritchtext:''
    }
  },
  created() {
    let that=this
    that.$api.get('/home/'+2).then(res=>{
      that.titlePic=res.rows[0].titlePic.split(',')
      let ritchtext=res.rows[0].moduleDetail
      that.ritchtext=that.showHtml(ritchtext)
    })
  },
  methods:{
    showHtml(str){
      return str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&lt;/g,"<")
          .replace(/&gt;/g,">")
          .replace(/&quot;/g,"\"")
          .replace(/&#39;/g, "\"")
          .replace(/&amp;nbsp;/g,'\u3000')
    },
  },
  components: {
    // navbar,
    top,
    bottom
  },
}
</script>

<style scoped>
.con{
  width: 87.437rem;
  padding: 1.5rem;
  margin-bottom: 1.81rem;
  background: #FFFFFF;
  border-radius: 0.625rem;
  margin-top: 1.68rem;
  margin-left: 25.75rem;
  z-index:9999;
}
.con>>>img{
  width: 100%;
  display: block;
  object-fit: fill;
}
.con>>>p{
  width: 100%;
  display: block;
  white-space: pre-wrap;
}
</style>